import PickUtils from "../../../../../common/pick-utils"
import { IBracketUtilMatchup } from "@cbs-sports/sports-shared-client/build/cjs/utils/common-utils-types"

//{Variant: [Base Height, Base Gap]}
export const BASE_MATCHUP_HEIGHT: Record<Variant, [number, number]> = {
  REGULAR: [14, 16],
  SWEET16: [68, 48],
  WOMENS: [14, 16],
}

//{Variant: [Base Width]}
export const BASE_MATCHUP_WIDTH: Record<Variant, number> = {
  REGULAR: 64,
  SWEET16: 96,
  WOMENS: 64,
}

export const SPONSOR_LOGOS: Record<Sponsor, string> = {
  ATT: "/static/printable-assets/att_logo.png",
  NISSAN: "/static/printable-assets/nissan_logo.png",
}
export const CBS_SPORTS_LOGO_URL = "/static/printable-assets/cbs_sports_logo.png"
export const CBS_LOGO_URL = "/static/printable-assets/cbs_logo.png"
export const FF_LOGO_URL = "/static/printable-assets/2025_ff_logo.png"
export const FF_WOMENS_LOGO_URL = "/static/printable-assets/2025_ff_womens_logo.png"
export const BRACKET_GAMES_LOGO = "/static/printable-assets/bracket_games_logo.png"
export const WOMENS_BRACKET_GAMES_LOGO = "/static/printable-assets/womens_bracket_games_logo.png"
export const MENS_BRACKET_GAMES_LOGO = "/static/printable-assets/mens_bracket_games_logo.png"
export const DEFAULT_FONT_FAMILY = "FSIndustrie"
export const DEFAULT_COLOR = "#000000"
export const SECONDARY_DEFAULT_COLOR = "#475966"
export const SELECTION_CORRECT_COLOR = "#008A22"
export const SELECTION_INCORRECT_COLOR = "#CC1414"
export const FINAL_MATCHUP_BACKGROUND = "#F2F5F7"
export const FINAL_MATCHUP_TEAMS_DIMENSIONS: Dimension = [360, 32]
export const FINAL_MATCHUP_CHAMPION_DIMENSIONS: Dimension = [132, 56]
export const FINAL_MATCHUP_DIMENSIONS: Dimension = [
  Math.max(FINAL_MATCHUP_TEAMS_DIMENSIONS[0], FINAL_MATCHUP_CHAMPION_DIMENSIONS[0]),
  Math.max(FINAL_MATCHUP_TEAMS_DIMENSIONS[1], FINAL_MATCHUP_CHAMPION_DIMENSIONS[1]),
]

const SWEET_16_TOURNAMENT_ROUNDS: RoundDescription[] = [
  { roundName: "Sweet 16", roundDate: "March 27-28" },
  { roundName: "Elite 8", roundDate: "March 29-30" },
  { roundName: "Final Four", roundDate: "April 5" },
  { roundName: "National Championship", roundDate: "April 7" },
]

const REGULAR_TOURNAMENT_ROUNDS: RoundDescription[] = [
  { roundName: "First Round", roundDate: "March 20-21" },
  { roundName: "Second Round", roundDate: "March 22-23" },
  ...SWEET_16_TOURNAMENT_ROUNDS,
]

const WOMENS_TOURNAMENT_ROUNDS: RoundDescription[] = [
  { roundName: "First Round", roundDate: "March 21-22" },
  { roundName: "Second Round", roundDate: "March 23-24" },
  { roundName: "Sweet 16", roundDate: "March 28-29" },
  { roundName: "Elite 8", roundDate: "Marh 30-31" },
  { roundName: "Final Four", roundDate: "April 4" },
  { roundName: "National Championship", roundDate: "April 6" },
]

export const TOURNAMENT_ROUNDS: Record<Variant, RoundDescription[]> = {
  REGULAR: REGULAR_TOURNAMENT_ROUNDS,
  SWEET16: SWEET_16_TOURNAMENT_ROUNDS,
  WOMENS: WOMENS_TOURNAMENT_ROUNDS,
}
export type Dimension = [number, number]

export interface RoundDescription {
  roundName: string
  roundDate: string
}

export interface IHavePickUtils {
  pickUtils: PickUtils
}

export interface ISectionProps extends IHavePickUtils {
  variant: Variant
}

export interface ISubsectionProps extends IHavePickUtils {
  variant: Variant
  matchups?: IBracketUtilMatchup[][]
  mirrored?: boolean
}

export type IRegionTreeProps = ISubsectionProps

export interface IRegionRoundProps extends IHavePickUtils {
  matchups?: IBracketUtilMatchup[]
  baseHeight: number
  baseWidth: number
  mirrored?: boolean
}

export interface IFinalMatchupProps extends IHavePickUtils {
  matchup: IBracketUtilMatchup
}

export interface IMatchupProps extends IHavePickUtils {
  matchup: IBracketUtilMatchup
  baseWidth: number
  baseHeight: number
  mirrored?: boolean
}

export type Sponsor = "NISSAN" | "ATT"
export type Variant = "REGULAR" | "SWEET16" | "WOMENS"

export const isTopSubSection = (matchup: IBracketUtilMatchup) => {
  return matchup.groupPosition.startsWith("top")
}

export const isBottomSubSection = (matchup: IBracketUtilMatchup) => {
  return matchup.groupPosition.startsWith("bottom")
}

export const getHeightForRound = (roundIndex: number, baseSize: [number, number]): [number, number] => {
  if (roundIndex === 0) {
    return baseSize
  }
  const [prevHeight, prevGap] = getHeightForRound(roundIndex - 1, baseSize)
  return [prevHeight + prevGap, prevHeight + prevGap]
}
